(function ($) {
  'use strict';

  const searchTriggerSelector = '.js-gnav-search-util-trigger';
  const gnavUtilSelector = '.js-gnav-util';
  const searchInputSelector = '.js-end-search-term';

  const gnavActiveClass = 'gnav-active';
  const hiddenClass = 'hidden';

  Drupal.behaviors.gnavSearch = {
    attach: function (context, settings) {
      // Fixed the Safari + iphone, ipad bug for fixed parent element + focus input
      // Jira CX-7497

      if (/iPhone|iPod|Android|iPad/.test(window.navigator.platform)) {
        $('#search').focusin(function (event) {
          $('body').removeClass('fixfixed');
          $('header.site-header').css({ top: '' });
          if (event.isSimulated) {
            var currentTop = $('header.site-header').position().top;

            $('header.site-header').css({ top: currentTop + 'px' });
            $('body').addClass('fixfixed');
          }
        });
        $('#search').focusout(function (event) {
          $('body').removeClass('fixfixed');
          $('header.site-header').css({ top: '' });
        });
      }

      // Search overlay own handling, BEX8-406
      const $body = $('body', context);
      const $searchTrigger = $(searchTriggerSelector, context);
      const $searchBlockContent = $searchTrigger.siblings(`${gnavUtilSelector}__content`);
      const $searchInput = $searchBlockContent.find(searchInputSelector);

      // Handle search trigger
      $searchTrigger.once().on('click', (event) => {
        // If search overlay disabled allow default redirect
        if (Drupal?.settings?.disable_search_overlay) return;
        // handle overlay behavior
        event.preventDefault();

        if ($searchBlockContent.hasClass(hiddenClass)) {
          $body.addClass(gnavActiveClass);
          $searchBlockContent.removeClass(hiddenClass);
          $searchInput.val('').trigger('focus');
        } else {
          $searchBlockContent.addClass(hiddenClass);
          $body.removeClass(gnavActiveClass);
        }
      });
    }
  };
})(jQuery);
